import React from 'react';
import {checkUserRight, compose, convertFromErrorObject, getAllModalFunctions} from "../../../utils";
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
    adminGetAllCompanies, adminGetAllOptions
} from "../../../actions";

import ModalAddEditGroup from "../../modals/modal-add-edit-group";
import ModalViewGroup from "../../modals/modal-view-group";

import {Button} from "react-bootstrap";
import DataViewer from "../../elements/data-viewer";
import ModalSendMessageUser from '../../modals/modal-send-message-user'


class Groups extends DataViewer {


    /*  define count of modal*/
    modalWindowsCount = 2;

    tableFilters = {
        id: {
            name: 'Filter by ID',
            type: 'integer',
            default: 0
        },
        deleted: {
            name: 'Show deleted elements',
            type: 'integer',
            default: 0
        },
        name: {
            name: 'Name',
            type: 'string',
            default: undefined
        },
        company_name: {
            name: 'Company name',
            type: 'string',
            default: undefined
        }
    }

    functions = {
        getAll: this.props.tlService.getFilteredGroups,
        massDelete: data => this.actionMassDelete(data),
        massMessaging: data => this.actionMassMessagingGroup(data)
    };

    modalComponents = {
        modalAdd: ModalAddEditGroup,
        modalEdit: ModalAddEditGroup,
        modalView: ModalViewGroup,
        modalSendMessage: ModalSendMessageUser
    };

    componentDidMount() {
        const {
            userData: {token, user},
            t: translate
        } = this.props;

        this.columns_default = [
            {
                name: 'id',
                selector: 'id',
                sortable: true
            },
            {
                name: 'name',
                selector: 'name',
                sortable: true
            },
            {
                name: 'company_name',
                selector: 'company_name',
                sortable: true
            },
            {
                name: 'create_time',
                selector: 'create_time',
                sortable: true

            },
            {
                name: 'delete_time',
                selector: 'delete_time',
                sortable: true

            },
            {
                name: 'user_group_options',
                selector: 'user_group_options',
                cell: row => typeof row.user_group_options === 'object' ? Object.keys(row.user_group_options).join(', ') : row.user_group_options
            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye"/></Button>
                            {(checkUserRight(user, [208]) && (user.user_type_id === 1 || row.company_id === user.company_id)) &&
                            (<>
                                <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit"/></Button>
                                <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash"/></Button>
                            </>)

                            }
                        </div>
                    )
                }
            }
        ];

        // check for enable page
        this.renderIt = checkUserRight(user, [ 206, 207, 306, 307]);

        // check add right
        this.addButton = checkUserRight(user, [207, 307]);


        this.props.adminGetAllCompanies(token);
        this.props.adminGetAllOptions(token);



        this.modalSettings['modalAdd'] = {
            action: this.actionAdd,
            data: {
                name: '',
                company_id: 0,
                user_group_options: {}
            },
            show: false,
            header: translate('Add Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                name: '',
                company_id: 0,
                user_group_options: {}
            },
            show: false,
            header: translate('Edit Group'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                name: '',
                company_id: 0,
                user_group_options: {}
            },
            show: false,
            header: translate('View Group'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalSendMessage'] = {
            action: this.actionMessage,
            data: {
                id: false,
                level: "alert",
                from_inf: "admin",
                template: "text_message",
                text: "",
            },
            show: false,
            header: translate('Send message to user'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Send'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Group'),
            footer: true,
            text: translate("Delete group?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        };
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Groups'),
            footer: true,
            text: translate('Delete Selected Groups'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {
            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'id',
                order: 0
            },
            page: {
                pageHeader: translate('Groups'),
                addNewText: translate('Add new group'),
            }
        });
        super.componentDidMount();
    }

    actionAdd = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        let id = false;

        try {
           const ret = await tlService.addGroup(token, parseInt(data.company_id), data.name);
           id = ret.id
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }
        // options
        for (let tmpOption of Object.keys(data.user_group_options)) {
            if (data.user_group_options[tmpOption] !== undefined) {
                const stringOption = typeof data.user_group_options[tmpOption] === 'object'? JSON.stringify(data.user_group_options[tmpOption]) : String(data.user_group_options[tmpOption]).trim()
                if (stringOption.length > 0) {
                    try {
                        await tlService.addGroupOption(token, id, tmpOption, stringOption)
                    } catch (e) {
                        console.log('error ' + convertFromErrorObject(t, e));
                        this.setState({ error: convertFromErrorObject(t, e) });
                        return false;
                    }
                }
            }
        }
        return true;
    }

    actionEdit = async (data) => {
        const {t} = this.props;
        const {
            tlService,
            userData: {token},
        } = this.props;

        try {
            await tlService.updateGroup(token, parseInt(data.id),parseInt(data.company_id), data.name);
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }

        // options
        try {
             await tlService.deleteGroupOption(token, parseInt(data.id));
        } catch (e) {
            console.log('error ' + convertFromErrorObject(t,e));
            this.setState({error: convertFromErrorObject(t,e)});
            return false;
        }

        for (let tmpOption of Object.keys(data.user_group_options)) {
            if (data.user_group_options[tmpOption] !== undefined) {
                const stringOption = typeof data.user_group_options[tmpOption] === 'object'? JSON.stringify(data.user_group_options[tmpOption]) : String(data.user_group_options[tmpOption]).trim()
                if (stringOption.length > 0) {
                    try {
                        await tlService.addGroupOption(token, parseInt(data.id), tmpOption, stringOption)
                    } catch (e) {
                        console.log('error ' + convertFromErrorObject(t,e));
                        this.setState({error: convertFromErrorObject(t,e)});
                        return false;
                    }
                }
            }
        }
        return true;
    }

    actionDelete = async (data) => {
        const {t} = this.props;

        const {
            tlService,
            userData: {token},
        } = this.props;

        // delete group
        if (data.id > 2) {
            try {
                await tlService.deleteGroup(token, parseInt(data.id));
            } catch (e) {
                console.log('error ' + convertFromErrorObject(t,e));
                this.setState({error: convertFromErrorObject(t,e)});
                return false;
            }
        }
        return true;
    }

    actionMassMessagingGroup =  async (data) => {
        const {
            tlService,
            userData: {token},
            t: translate
        } = this.props;
        if (data.length > 0) {

            const group_ids = []
            const user_ids = []
            for (let id of data) {
                group_ids.push(id['id'])
            }

            try {
                const res = await tlService.getFilteredUsers(token, {},
                  {
                      return_values: '["id", "user_name", "email"]',
                      extended_filter:`[["&user_group_id","i=[${group_ids.join(',')}]"]]`
                  })
                if (res && res.data.length > 0) {
                    for (let id of res.data) {
                        user_ids.push(id)
                    }
                }
            } catch (e) {
                console.log('error ' + convertFromErrorObject(translate, e))
                this.setState({ error: convertFromErrorObject(translate, e) })
                return false
            }
            const rows = {user_ids}
            this.openModal(0, rows, 'modalSendMessage')
        }
    }

}

const mapStateToProps = ({userData, modalData, openData, adminData}) => {
    return {userData, openData, modalData, adminData};
};

const mapDispatchToProps = (dispatch, {tlService}) => {
    return bindActionCreators({
        ...getAllModalFunctions(),
        adminGetAllCompanies: adminGetAllCompanies(tlService),
        adminGetAllOptions: adminGetAllOptions(tlService)

    }, dispatch);
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(Groups);
