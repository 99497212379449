import React, { Component } from "react"
import { withTranslation } from 'react-i18next'
import { Col, Container, Form, Row } from "react-bootstrap"
import { compose, getAllModalFunctions, getDataFromModals } from "../../../utils"
import { withTLService } from "../../hoc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import config from "../../../config"

class ModalViewMessage extends Component {

    state = {
        tmpOptions: []
    }

    render() {
        const { index } = this.props
        const data = getDataFromModals(this.props, index)
        const { id, header, level, template, from_inf, text, create_time, answer_time, read_time, email_time, user_id, answer } = data
        const {
            t: translate
        } = this.props

        const levels = config.messagesLevels
        const templates = config.messagesTemplates

        return (
            <Container>
                <Row>
                    <Col md={6}>
                        <Form.Group controlId="id">
                            <Form.Label>{translate("ID")}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={id}
                                name="id"
                                type="text"
                            />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="level">
                            <Form.Label>{translate("Level")}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={level}
                                name="level"
                                as="select">
                                {
                                    levels.map((level, index) => {
                                        return (
                                            <option key={index} value={level}>{translate(level)}</option>
                                        )
                                    })
                                }
                            </Form.Control>

                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="template">
                            <Form.Label>{translate("Template")}</Form.Label>
                            <Form.Control
                                disabled={true}
                                value={template}
                                name="template"
                                as="select">
                                {
                                    templates.map((template, index) => {
                                        return (
                                            <option key={index} value={template}>{translate(template)}</option>
                                        )
                                    })
                                }
                            </Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="header">
                            <Form.Label>{translate("Header")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="header"
                                defaultValue={header}
                                disabled={true}
                                placeholder={translate("No Header")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="from_inf">
                            <Form.Label>{translate("From")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="from_inf"
                                defaultValue={from_inf}
                                disabled={true}
                                placeholder={translate("No From Info")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="user_id">
                            <Form.Label>{translate("User ID")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="user_id"
                                defaultValue={user_id}
                                disabled={true}
                                placeholder={translate("No User Info")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="create_time">
                            <Form.Label>{translate("Create Time")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="create_time"
                                defaultValue={create_time}
                                disabled={true}
                                placeholder={translate("No Create Time")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="answer_time">
                            <Form.Label>{translate("Answer Time")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="answer_time"
                                defaultValue={answer_time}
                                disabled={true}
                                placeholder={translate("Not Answered")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="read_time">
                            <Form.Label>{translate("Read Time")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="read_time"
                                defaultValue={read_time}
                                disabled={true}
                                placeholder={translate("Not Read")} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group controlId="email_time">
                            <Form.Label>{translate("Email Time")}</Form.Label>
                            <Form.Control
                                type="text"
                                name="email_time"
                                defaultValue={email_time}
                                disabled={true}
                                placeholder={translate("No Email Sent")} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="text">
                            <Form.Label>{translate("Text")}</Form.Label>
                            <div dangerouslySetInnerHTML={{ __html: text?.match(/<[^>]+>/) ? text : text.replace(/\n/g, '<br/>') }} style={{ border: "1px solid #ced4da", padding: "15px", borderRadius: "5px", overflow: "auto", backgroundColor: "#eaecf4" }} />
                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group controlId="answer">
                            <Form.Label>{translate("Answer")}</Form.Label>
                            <div dangerouslySetInnerHTML={{ __html: answer?.match(/<[^>]+>/) ? answer : answer.replace(/\n/g, '<br/>') }} style={{ border: "1px solid #ced4da", padding: "15px", borderRadius: "5px", overflow: "auto", backgroundColor: "#eaecf4" }} />
                        </Form.Group>
                    </Col>
                </Row>
            </Container>
        )
    }
}

const mapStateToProps = ({ userData, modalData, openData, adminData }) => {
    return { userData, openData, modalData, adminData }
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators({
        ...getAllModalFunctions()
    }, dispatch)
}

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(ModalViewMessage)