import React from 'react'
import { compose, convertFromErrorObject, getAllModalFunctions } from "../../../utils"
import { withTranslation } from "react-i18next"
import { withTLService } from "../../hoc"
import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { Button } from "react-bootstrap"
import DataViewer from "../../elements/data-viewer"
import ModalViewMessage from '../../modals/modal-view-message'
import ModalAddEditMessage from '../../modals/modal-add-edit-message'


class SentMessages extends DataViewer {

    modalWindowsCount = 2;

    sentMessage = true;

    extendedFilter = true;

    tableFilters = {
        user_id: {
            name: 'Filter by USER ID',
            type: 'integer',
            default: undefined
        },
        text: {
            name: 'Text',
            type: 'string',
            default: undefined
        },
        from_inf: {
            name: 'From',
            type: 'string',
            default: undefined
        },
        level: {
            name: 'Level',
            type: 'select',
            dir: [
                ['alert', 'Alert'],
                ['warning', 'Warning'],
                ['info', 'Info']
            ],
            default: undefined
        },
        template: {
            name: 'Template',
            type: 'select',
            dir: [
                ['text_message', 'Text message'],
                ['text_message_no_answer', 'Text message no answer'],
                ['must_change_password', 'Must change password'],
                ['terms_and_conditions', 'Terms and conditions'],
                ['set_2fa_ga', 'Set 2FA GA']
            ],
            default: undefined
        },
        read_time: {
            name: 'Read time',
            type: 'switch',
            default: 1
        },
        answer_time: {
            name: 'Answer time',
            type: 'switch',
            default: 1
        },
        email_time: {
            name: 'Email time',
            type: 'switch',
            default: 1
        },
    }

    functions = {
        getAllMessages: this.props.tlService.getAllFilteredMessages,
        massDelete: data => this.actionMassDelete(data),
    };

    modalComponents = {
        modalEdit: ModalAddEditMessage,
        modalView: ModalViewMessage,
    };

    componentDidMount() {
        const {
            userData: { user },
            t: translate,
        } = this.props

        this.columns_default = [
            {
                name: 'Header',
                selector: 'header',
            },
            {
                name: 'Level',
                selector: 'level',
                sortable: true
            },
            {
                name: 'From',
                selector: 'from_inf',
                sortable: true
            },
            {
                name: 'User ID',
                selector: 'user_id',
                sortable: true
            },
            {
                name: 'create_time',
                selector: 'create_time',
                sortable: true,
            },
            {
                name: 'read',
                selector: 'read_time',
                sortable: true,
                cell: row => row.read_time && <i className="fas fa-check" /> 
            },
            {
                name: 'Email',
                selector: 'email_time',
                sortable: true,
                cell: row => row.email_time && <i className="fas fa-check" />
            },
            {
                name: 'Answer',
                selector: 'answer',
            },
            {
                name: 'edit',
                selector: 'id',
                cell: row => {
                    return (
                        <div className="btn-group">
                            <Button onClick={() => this.openModal(0, row, 'modalView')}><i className="fas fa-eye" /></Button>
                            <Button onClick={() => this.openModal(0, row, 'modalEdit')} className="ml-2"><i className="fas fa-edit" /></Button>
                            <Button onClick={() => this.openModal(0, row, 'modalDelete')} className="ml-2"><i className="fas fa-trash" /></Button>
                        </div>
                    )
                }
            }
        ]

        // check for enable page
        this.renderIt = user?.user_type_id === 1

        // check add right
        this.addButton = false

        this.modalSettings['modalEdit'] = {
            action: this.actionEdit,
            data: {
                id: '',
                header: '',
                level: [],
                from_inf: '',
                text: '',
                create_time: '',
                answer_time: '',
                read_time: ''
            },
            show: false,
            header: translate('Edit Message'),
            footer: true,
            text: '',
            saveButton: true,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalView'] = {
            action: false,
            data: {
                id: '',
                header: '',
                level: '',
                from_inf: '',
                text: '',
                create_time: '',
                answer_time: '',
                read_time: ''
            },
            show: false,
            header: translate('View Message'),
            footer: true,
            text: '',
            saveButton: false,
            saveButtonText: translate('Save'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalDelete'] = {
            action: this.actionDelete,
            data: {
                id: false,
            },
            show: false,
            header: translate('Delete Message'),
            footer: true,
            text: translate("Delete Message?"),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }
        this.modalSettings['modalMassDelete'] = {
            action: this.actionDoMassDelete,
            data: {},
            show: false,
            header: translate('Delete Selected Messages'),
            footer: true,
            text: translate('Delete Selected Messages?'),
            saveButton: true,
            saveButtonText: translate('Delete'),
            closeButton: true,
            closeButtonText: translate('Close'),
            ok: true
        }

        this.setState({
            filters: {

            },
            limits: {
                limit: 10,
                offset: 0,
                order_by: 'create_time',
                order: 1
            },
            page: {
                pageHeader: translate('Sent Messages'),
                addNewText: translate('Add new Message'),
            }
        })
        super.componentDidMount()
    }

    actionEdit = async (data) => {
        const { t } = this.props
        const {
            tlService,
            userData: { token },
        } = this.props

        const { mark_read, sent_email } = data
        
            if (mark_read) {
                try {
                    await tlService.setMessageRead(token, parseInt(data.id))
                } catch (e) {
                    console.log('error ' + convertFromErrorObject(t, e))
                    this.setState({ error: convertFromErrorObject(t, e) })
                    return false
                }
            }

            if (sent_email) {
                try {
                    await tlService.setMessageSentEmail(token, parseInt(data.id))
                } catch (e) {
                    console.log('error ' + convertFromErrorObject(t, e))
                    this.setState({ error: convertFromErrorObject(t, e) })
                    return false
                }
            }

        return true
    }
    actionDelete = async (data) => {
        const { t } = this.props

        const {
            tlService,
            userData: { token },
        } = this.props

        // delete message
        if (data.id > 2) {
            try {
                await tlService.deleteMessage(token, parseInt(data.id))
            } catch (e) {
                console.log('error ' + convertFromErrorObject(t, e))
                this.setState({ error: convertFromErrorObject(t, e) })
                return false
            }
        }
        return true
    }


}

const mapStateToProps = ({ userData, modalData, openData, adminData }) => {
    return { userData, openData, modalData, adminData }
}

const mapDispatchToProps = (dispatch, { tlService }) => {
    return bindActionCreators({
        ...getAllModalFunctions(),


    }, dispatch)
}

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps, mapDispatchToProps)
)(SentMessages)
