import React, {Component} from "react";
//import {bindActionCreators} from 'redux';
//import {connect} from 'react-redux';
import {compose, checkUserRight} from '../../../utils';
import {withTranslation} from "react-i18next";
import {withTLService} from "../../hoc";
import {inter} from "./inter";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import textLabLogo from "./logo/textlab_logo.png"

class Sidebar extends Component {


    componentDidMount() {
        inter()
    }

    render() {
        const {
            t: translate,
            userData: {user},
            match: {path}
        } = this.props;

        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">

                <Link className="sidebar-brand d-flex align-items-center justify-content-center" to="/">
                    <div className="sidebar-brand-icon rotate-n-15">
                        <img alt={translate('home')} src={textLabLogo} style={{transform: "rotate(16deg)", width: "30px", height: "30px", marginLeft: "-40px", marginTop: "-15px"}} />
                    </div>
                    <div className="sidebar-brand-text mx-3">TextLab</div>
                </Link>

                <hr className="sidebar-divider my-0"/>

                <li className={`nav-item ${path === "/" && "active"}`}>
                    <Link className="nav-link" to="/">
                        <i className="fas fa-fw fa-tachometer-alt"/>
                        <span>{translate("Dashboard")}</span></Link>
                </li>


                <hr className="sidebar-divider"/>

                {
                    checkUserRight(user,[101, 102, 103, 201, 202, 203]) && (
                        <li className={`nav-item ${path === "/users" && "active"}`}>
                            <Link className="nav-link" to="/users">
                                <i className="fas fa-fw fa-users"/>
                                <span>{translate("Users")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[206, 207, 208]) && (
                        <li className={`nav-item ${path === "/groups" && "active"}`}>
                            <Link className="nav-link" to="/groups">
                                <i className="fas fa-fw fa-users-cog"/>
                                <span>{translate("Groups")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[306, 307, 308]) && (
                        <li className={`nav-item ${path === "/companies" && "active"}`}>
                            <Link className="nav-link" to="/companies">
                                <i className="fas fa-fw fa-user-tie"/>
                                <span>{translate("Companies")}</span></Link>
                        </li>)
                }
                {
                    user?.user_type_id === 1 && (
                        <li className={`nav-item ${path === "/sent-messages" && "active"}`}>
                            <Link className="nav-link" to="/sent-messages">
                                <i className="fas fa-fw fa-envelope"/>
                                <span>{translate("Sent Messages")}</span></Link>
                        </li>)
                }
                <hr className="sidebar-divider d-none d-md-block"/>


                {
                    checkUserRight(user,[]) && (
                        <li className={`nav-item ${path === "/benchmarks" && "active"}`}>
                            <Link className="nav-link" to="/benchmarks">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("Benchmarks")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[]) && (
                        <li className={`nav-item ${path === "/terms" && "active"}`}>
                            <Link className="nav-link" to="/terms">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("Terms")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[]) && (
                        <li className={`nav-item ${path === "/allowlists" && "active"}`}>
                            <Link className="nav-link" to="/allowlists">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("Allowlists")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[]) && (
                        <li className={`nav-item ${path === "/textbins" && "active"}`}>
                            <Link className="nav-link" to="/textbins">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("textbins")}</span></Link>
                        </li>)
                }

                {
                    (user.user_type_id === 1) && (
                        <li className={`nav-item ${path === "/regexes" && "active"}`}>
                            <Link className="nav-link" to="/regexes">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("regexes")}</span></Link>
                        </li>)
                }

                {
                    checkUserRight(user,[151,251]) && (
                        <li className={`nav-item ${path === "/translations" && "active"}`}>
                            <Link className="nav-link" to="/translations">
                                <i className="fas fa-fw fa-tag"/>
                                <span>{translate("translations")}</span></Link>
                        </li>)
                }

              {
                (user.user_type_id === 1 || checkUserRight(user,[403])) && (
                  <li className={`nav-item ${path === "/reports" && "active"}`}>
                    <Link className="nav-link" to="/reports">
                      <i className="fas fa-fw fa-tag"/>
                      <span>{translate("reports")}</span></Link>
                  </li>)
              }

                <div className="text-center d-none d-md-inline">
                    <button className="rounded-circle border-0" id="sidebarToggle"/>
                </div>



            </ul>
        )
    }
}


const mapStateToProps = ({userData}) => {
    return {userData};
};

export default compose(
    withTranslation(),
    withTLService(),
    connect(mapStateToProps)
)(Sidebar);