import React from 'react'
import {
  checkUserRight,
  compose,
  convertFromErrorObject,
  deepCopy,
  getAllModalFunctions,
  isObjectEmpty,
  removeEmptyFromObject,
} from '../../../utils'

import { withTranslation } from 'react-i18next'
import { withTLService } from '../../hoc'
import { connect } from 'react-redux'

import { bindActionCreators } from 'redux'
import {
  adminGetAllCompanies,
  adminGetAllOptions,
  adminGetAllSettings,
} from '../../../actions'

import ModalAddEditCompany from '../../modals/modal-add-edit-company'
import ModalViewCompany from '../../modals/modal-view-company'

import { Button } from 'react-bootstrap'
import DataViewer from '../../elements/data-viewer'
import CompanyHeader from './company-header'
import ModalCopyBenchmarks from '../../modals/modal-copy-benchmarks'
import ModalSendMessageUser from '../../modals/modal-send-message-user'
import { isArray } from '../../../utils'

class Companies extends DataViewer {
  async getCompanyOptions (tlService, token, id) {
    try {
      const ret = await tlService.getCompanyById(token, id)
      if (ret && ret?.company_options?.default_company_settings) {
        this.setState({
          defaultCompanySettings: JSON.stringify(ret.company_options.default_company_settings),
        })
      }

      if (ret && ret?.company_options?.ai_settings) {
        this.setState({
          ai_settings: ret.company_options.ai_settings,
        })
      }
    } catch (e) {
      //console.log('error ' + convertFromErrorObject(t,e));
      //this.setState({error: convertFromErrorObject(t,e)});
      return false
    }
  }

  /*  define count of modal*/
  modalWindowsCount = 2

  tableFilters = {
    deleted: {
      name: 'Show deleted elements',
      type: 'integer',
      default: 0,
    },
    name: {
      name: 'Company name',
      type: 'string',
      default: undefined,
    },
  }

  functions = {
    getAll: this.props.tlService.getFilteredCompanies,
    massDelete: (data) => this.actionMassDelete(data),
    massMessaging: data => this.actionMassMessagingCompany(data)
  }

  modalComponents = {
    modalAdd: ModalAddEditCompany,
    modalEdit: ModalAddEditCompany,
    modalView: ModalViewCompany,
    modalCopyBenchmarks: ModalCopyBenchmarks,
    modalSendMessage: ModalSendMessageUser
  }

  async componentDidMount () {
    const {
      tlService,
      userData: { token, user },
      t: translate,
    } = this.props

    await this.getCompanyOptions(tlService, token, user.company_id)

    this.columns_default = [
      {
        name: 'id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'name',
        selector: 'name',
        sortable: true,
      },
      {
        name: 'company_licenses',
        selector: 'company_licenses',
        sortable: true,
        cell: (row) => JSON.stringify(row.company_licenses),
      },
      {
        name: 'company_options',
        selector: 'company_options',
        sortable: true,
        cell: row => typeof row.company_options === 'object' ? Object.keys(row.company_options).join(', ') : row.company_options
      },
      {
        name: 'delete_time',
        selector: 'delete_time',
        sortable: true,
      },
      {
        name: 'edit',
        selector: 'id',
        cell: (row) => {
          return (
            <div className="btn-group">
              <Button
                onClick={() =>
                  this.openModal(0, row, 'modalView')
                }
              >
                <i className="fas fa-eye"/>
              </Button>
              {checkUserRight(user, [308]) && (
                <>
                  <Button
                    onClick={() =>
                      this.openModal(0, row, 'modalEdit')
                    }
                    className="ml-2"
                  >
                    <i className="fas fa-edit"/>
                  </Button>
                  <Button
                    onClick={() =>
                      this.openModal(
                        0,
                        row,
                        'modalDelete'
                      )
                    }
                    className="ml-2"
                  >
                    <i className="fas fa-trash"/>
                  </Button>
                </>
              )}
            </div>
          )
        },
      },
    ]

    // check for enable page
    this.renderIt = checkUserRight(user, [306, 307, 308])

    // check add right
    this.addButton = checkUserRight(user, [307])

    this.props.adminGetAllCompanies(token)
    this.props.adminGetAllOptions(token)
    this.props.adminGetAllSettings(token)

    this.modalSettings['modalAdd'] = {
      action: this.actionAdd,
      data: {
        name: '',
        useMigration: true,
        add_ai_settings: true,
        defaultCompanySettings: this.state.defaultCompanySettings,
        ai_settings: this.state.ai_settings,
        company_licenses: [],
        company_options: {},
      },
      show: false,
      header: translate('Add Company'),
      footer: true,
      text: '',
      saveButton: true,
      saveButtonText: translate('Save'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }
    this.modalSettings['modalSendMessage'] = {
      action: this.actionMessage,
      data: {
        id: false,
        level: 'alert',
        from_inf: 'admin',
        template: 'text_message',
        text: '',
      },
      show: false,
      header: translate('Send message to user'),
      footer: true,
      text: '',
      saveButton: true,
      saveButtonText: translate('Send'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true
    }
    this.modalSettings['modalEdit'] = {
      action: this.actionEdit,
      data: {
        name: '',
        useMigration: false,
        defaultCompanySettings: '',
        company_licenses: [],
        company_options: {},
      },
      show: false,
      header: translate('Edit Company'),
      footer: true,
      text: '',
      saveButton: true,
      saveButtonText: translate('Save'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }
    this.modalSettings['modalView'] = {
      action: false,
      data: {
        name: '',
        company_licenses: [],
        company_options: {},
      },
      show: false,
      header: translate('View Company'),
      footer: true,
      text: '',
      saveButton: false,
      saveButtonText: translate('Save'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }
    this.modalSettings['modalDelete'] = {
      action: this.actionDelete,
      data: {
        id: false,
      },
      show: false,
      header: translate('Delete Company'),
      footer: true,
      text: translate('Delete company?'),
      saveButton: true,
      saveButtonText: translate('Delete'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }
    this.modalSettings['modalMassDelete'] = {
      action: this.actionDoMassDelete,
      data: {},
      show: false,
      header: translate('Delete Selected Companies'),
      footer: true,
      text: translate('Delete Selected Companies'),
      saveButton: true,
      saveButtonText: translate('Delete'),
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }
    this.modalSettings['modalCopyBenchmarks'] = {
      action: this.actionCopyBenchmarks,
      data: {
        name: '',
        useMigration: true,
        companiesList: '',
        createUGC: false,
        benchmarkID: 0,
        benchmark: {},
        tcList: [],
        company_licenses: [],
        company_options: {},
      },
      show: false,
      header: translate('Copy Benchmarks Into Companies'),
      footer: true,
      text: '',
      saveButton: true,
      saveButtonText: 'Copy benchmarks',
      closeButton: true,
      closeButtonText: translate('Close'),
      ok: true,
    }

    this.setState({
      filters: {},
      limits: {
        limit: 10,
        offset: 0,
        order_by: 'id',
        order: 0,
      },
      Header: CompanyHeader,
      page: {
        pageHeader: translate('Companies'),
        addNewText: translate('Add new company'),
      },
    })
    super.componentDidMount()
  }

  runForStrArray (oldArray, oldToNew) {
    for (let arrayKey in oldArray) {
      if (oldToNew[parseInt(oldArray[arrayKey])] !== undefined) {
        oldArray[arrayKey] =
          oldToNew[parseInt(oldArray[arrayKey])].toString()
      }
    }
    return oldArray
  }

  runForObject (oldObject, replacementSettings, oldToNew) {
    for (let objectKeyValue of Object.keys(oldObject)) {
      // run foreach
      if (!isObjectEmpty(replacementSettings['foreach'])) {
        for (let foreachKey of Object.keys(
          replacementSettings['foreach']
        )) {
          if (!isObjectEmpty(oldObject[objectKeyValue][foreachKey])) {
            if (
              replacementSettings['foreach'][foreachKey][
                'type'
                ] === 'strArray'
            ) {
              oldObject[objectKeyValue][foreachKey] =
                this.runForStrArray(
                  oldObject[objectKeyValue][foreachKey],
                  oldToNew
                )
            } else if (
              replacementSettings['foreach'][foreachKey][
                'type'
                ] === 'object'
            ) {
              oldObject[objectKeyValue][foreachKey] =
                this.runForObject(
                  oldObject[objectKeyValue][foreachKey],
                  replacementSettings['foreach'][foreachKey],
                  oldToNew
                )
            }
          }
        }
      }

      // change the object keys
      if (oldToNew[parseInt(objectKeyValue)] !== undefined) {
        oldObject[oldToNew[parseInt(objectKeyValue)].toString()] =
          deepCopy(oldObject[objectKeyValue])
        delete oldObject[objectKeyValue]
      }
    }
    return oldObject
  }

  /*
   const createdData = {
          companyId: id,
          categories: [],
          benchmarks: []
      }
  * */
  async deleteOnError (createdData, token, tlService) {
    if (createdData.companyId) {
      if (createdData.benchmarks?.length) {
        for (let id of createdData.benchmarks) {
          try {
            await tlService.deleteBenchmark(token, id)
          } catch (e) {
            console.log('error deleteBenchmark', id, e)
          }
        }
        for (let id of createdData.categories) {
          try {
            await tlService.deleteTermCategory(token, id)
          } catch (e) {
            console.log('error deleteTermCategory', id, e)
          }
        }
        try {
          await tlService.deleteCompany(token, createdData.companyId)
        } catch (e) {
          console.log(
            'error deleteCompany',
            createdData.companyId,
            e
          )
        }
      }
    }
  }

  actionCopyBenchmarks = async (data) => {
    const {
      tlService,
      userData: { token },
      adminData
    } = this.props

    let resultJson = {}

    const { createUGC, tcList, benchmark, resCompanies } = data

    let settingsList = {}
    if (adminData) {
      settingsList = adminData.settings_dir
    } else {
      console.log('no admin data')
    }

    const settingsTC = {}

    for (const [setting_, value_] of Object.entries(settingsList)) {
      if (value_.hasOwnProperty('values')) {
        for (const [key_, valueArray] of Object.entries(
          value_['values']
        )) {
          if (valueArray[0] === 'category' || key_ === '_list') {
            if (settingsTC.hasOwnProperty(setting_))
              settingsTC[setting_].push(key_)
            else settingsTC[setting_] = [key_]
          }
        }
      }
    }

    let settings = {}
    let new_settings = {}
    for (let set of Object.keys(benchmark['settings'])) {
      if (!(set in settingsTC)) {
        settings[set] = deepCopy(benchmark['settings'][set])
      } else {
        new_settings[set] = deepCopy(benchmark['settings'][set])
      }
    }

    for (const comp of resCompanies) {
      let errorFound = false
      let listTCIDs = []
      const companyAlias = comp.name + ' ' + comp.id
      resultJson[companyAlias] = []
      for (const _set of Object.keys(new_settings)) {
        const filterTC = tcList.filter((tc) => tc.settings == _set)

        // empty term category _list
        if (filterTC.length === 0) {
          settings[_set] = new_settings[_set]
          console.log('EMPTYFILTER', _set)
          continue
        }

        if (settingsTC[_set].includes('_list')) {
          let tmpList = {}
          for (const tc of filterTC) {
            if (tc.ignore) continue
            let newTCID = 0
            if (tc.clone && !tc.child) {
              try {
                const newTC = {
                  name: JSON.stringify({
                    de: tc['tc_data']['name']['de'],
                    en:
                      tc['tc_data']['name']['en'] +
                      ' ' +
                      comp.name,
                  }),
                }
                const resCloneCat =
                  await tlService.cloneTermCategory(
                    token,
                    tc.id,
                    newTC
                  )
                // console.log("resCloneCat", resCloneCat);
                newTCID = resCloneCat?.id
                listTCIDs.push(newTCID)
              } catch (e) {
                console.log(
                  'error in clone tc in _list',
                  tc.tc_data.name,
                  e
                )
                errorFound = true
                resultJson[companyAlias].push(
                  'error in clone tc in _list ' +
                  tc.tc_data.name +
                  ' ' +
                  e
                )
              }
            }

            if (tc.child && !tc.clone) {
              try {
                const newTCName = JSON.stringify({
                  de: tc['tc_data']['name']['de'],
                  en:
                    tc['tc_data']['name']['en'] +
                    ' ' +
                    comp.name,
                })
                const resChild =
                  await tlService.addTermCategory(
                    token,
                    tc.tc_data.locale_name,
                    newTCName,
                    JSON.stringify(tc.tc_data.description),
                    tc.tc_data.category_type,
                    removeEmptyFromObject(
                      tc.tc_data.settings
                    ),
                    tc.tc_data.global_visible,
                    tc.id
                  )
                // console.log("child created ", resChild);
                newTCID = resChild?.id
                listTCIDs.push(newTCID)
              } catch (e) {
                console.log(
                  'error on create child',
                  tc.name,
                  e
                )
                errorFound = true
                resultJson[companyAlias].push(
                  'error on create child in _list ' +
                  tc.tc_data.name +
                  ' ' +
                  e
                )
              }
            }

            if (!tc.clone && !tc.child) {
              // not clone nor child (use source tc in new benchmark)
              newTCID = tc.id
            }

            if (newTCID > 0) {
              tmpList[newTCID + ''] = deepCopy(
                new_settings[_set]['values']['_list'][
                tc.id + ''
                  ]
              )

              if (tc.ugc) {
                if (
                  tc.tc_data.rights.some((r1_) =>
                    r1_.some((r2_) => r2_[0] === comp.id)
                  )
                ) {
                  console.log(
                    'UGC exist for ',
                    newTCID,
                    ' in company',
                    comp.name
                  )
                  resultJson[companyAlias].push(
                    'UGC exist for ' +
                    newTCID +
                    ' in company ' +
                    comp.name
                  )
                } else {
                  try {
                    await tlService.addTermCategoryUGC(
                      token,
                      newTCID,
                      0,
                      0,
                      comp.id
                    )
                    // console.log("UGC created for term category ", newTCID, "in company", comp.id);
                  } catch (e) {
                    console.log(
                      'error on create term category ugc',
                      e
                    )
                    errorFound = true
                    resultJson[companyAlias].push(
                      'error on create term category ugc ' +
                      e
                    )
                  }
                }
              }
            }
          }
          settings[_set] = deepCopy(new_settings[_set])
          settings[_set]['values']['_list'] = deepCopy(tmpList)
        } else {
          for (const tc of filterTC) {
            let newTCID = 0

            if (tc.ignore) {
              continue
            }

            if (tc.clone && !tc.child) {
              try {
                const newTC = {
                  name: JSON.stringify({
                    de: tc['tc_data']['name']['de'],
                    en:
                      tc['tc_data']['name']['en'] +
                      ' ' +
                      comp.name,
                  }),
                }
                const resCloneCat =
                  await tlService.cloneTermCategory(
                    token,
                    tc.id,
                    newTC
                  )
                // console.log("resCloneCat", resCloneCat);
                newTCID = resCloneCat?.id
                listTCIDs.push(newTCID)
              } catch (e) {
                console.log(
                  'error in clone tc',
                  tc.tc_data.name,
                  e
                )
                errorFound = true
                resultJson[companyAlias].push(
                  'error in clone tc' +
                  tc.tc_data.name +
                  ' ' +
                  e
                )
              }
            }

            if (tc.child && !tc.clone) {
              try {
                const newTCName = JSON.stringify({
                  de: tc['tc_data']['name']['de'],
                  en:
                    tc['tc_data']['name']['en'] +
                    ' ' +
                    comp.name,
                })
                const resChild =
                  await tlService.addTermCategory(
                    token,
                    tc.tc_data.locale_name,
                    newTCName,
                    JSON.stringify(tc.tc_data.description),
                    tc.tc_data.category_type,
                    removeEmptyFromObject(
                      tc.tc_data.settings
                    ),
                    tc.tc_data.global_visible,
                    tc.id
                  )
                // console.log("child created ", resChild);
                newTCID = resChild?.id
                listTCIDs.push(newTCID)
              } catch (e) {
                console.log(
                  'error on create child',
                  tc.name,
                  e
                )
                errorFound = true
                resultJson[companyAlias].push(
                  'error on create child ' + tc.name + ' ' + e
                )
              }
            }

            if (!tc.child && !tc.clone) {
              newTCID = tc.id
            }

            if (newTCID > 0) {
              settings[_set] = new_settings[_set]
              settings[_set]['values'][tc.field] = newTCID

              if (tc.ugc) {
                if (
                  tc.tc_data.rights.some((r1_) =>
                    r1_.some((r2_) => r2_[0] === comp.id)
                  )
                ) {
                  // console.log("UGC exist for ", newTCID, " in company", comp.name);
                  resultJson[companyAlias].push(
                    'UGC exist for ' +
                    newTCID +
                    ' in company ' +
                    comp.name
                  )
                } else {
                  try {
                    await tlService.addTermCategoryUGC(
                      token,
                      newTCID,
                      0,
                      0,
                      comp.id
                    )
                  } catch (e) {
                    console.log(
                      'error on create term category ugc',
                      e
                    )
                    errorFound = true
                    resultJson[companyAlias].push(
                      'error on create term category ugc ' +
                      e
                    )
                  }
                }
              }
            } else {
              errorFound = true
              resultJson[companyAlias].push(
                'couldn\'t create/assign term category to new benchmark'
              )
            }
          }
        }
      }

      // add benchmark
      const b = { ...benchmark, settings, new_settings }
      const new_name = deepCopy(benchmark.name)
      new_name.en += ' ' + comp.name
      let newBenchmarkID = 0
      try {
        const newBenchmark = await tlService.addBenchmark(
          token,
          JSON.stringify(new_name),
          JSON.stringify(b['description']),
          b['benchmark_template_id'],
          JSON.stringify(b['calcelements_setting']),
          JSON.stringify(b['return_values']),
          JSON.stringify(b['settings'])
        )
        newBenchmarkID = newBenchmark?.id
      } catch (e) {
        console.log('error create new benchmark', b, e)
        errorFound = true
        resultJson[companyAlias].push(
          'error create new benchmark ' + e
        )
      }

      if (newBenchmarkID > 0 && createUGC) {
        try {
          await tlService.addBenchmarkUGC(
            token,
            newBenchmarkID,
            0,
            0,
            comp.id
          )
          console.log(
            'UGC created for benchmark ',
            newBenchmarkID,
            'in company',
            comp.name
          )
        } catch (e) {
          console.log('error on create benchmark ugc', e)
          errorFound = true
          resultJson[companyAlias].push(
            'error on create benchmark ugc ' + e
          )
        }
      }

      // in the case of error -> delete benchmark and tc
      if (errorFound) {
        // remove benchmark
        if (newBenchmarkID > 0) {
          try {
            await tlService.deleteBenchmark(token, newBenchmarkID)
          } catch (e) {
            console.log(
              'can not remove benchmark ' + newBenchmarkID
            )
          }
        }

        // remove term categories
        console.log('LIST TC for DELETE', listTCIDs)
        for (let cat in listTCIDs) {
          try {
            await tlService.deleteTermCategory(
              token,
              listTCIDs[cat]
            )
          } catch (e) {
            console.log(
              'can not remove term category ' + listTCIDs[cat]
            )
          }
        }
      } else {
        resultJson[companyAlias].push('Success')
      }
    }
    console.log(resultJson)
    return true
  }

  actionAdd = async (data) => {
    const { t } = this.props
    const {
      tlService,
      userData: { token },
      t: translate,
    } = this.props

    let defaultCompanySettings = {}
    try {
      defaultCompanySettings = JSON.parse(data.defaultCompanySettings)
    } catch (e) {
      console.log('error convert')
      //return false;
    }

    let id = false

    if (data.company_licenses.length < 1) {
      this.setState({ error: translate('Must be at least one license') })
      return false
    }
    const newLicenses = data.company_licenses.map((lic) => parseInt(lic))
    const companyName = data.name
    try {
      const ret = await tlService.addCompany(
        token,
        newLicenses,
        companyName
      )
      id = ret.id
    } catch (e) {
      console.log('error ' + convertFromErrorObject(t, e))
      this.setState({ error: convertFromErrorObject(t, e) })
      return false
    }

    if (!id) {
      return false
    }

    // default options
    if (defaultCompanySettings?.company_options) {
      for (let tmpOption of Object.keys(defaultCompanySettings.company_options)) {
        if (defaultCompanySettings.company_options[tmpOption] !== undefined) {
          const stringOption = typeof defaultCompanySettings.company_options[tmpOption] === 'object' ? JSON.stringify(defaultCompanySettings.company_options[tmpOption]) : String(defaultCompanySettings.company_options[tmpOption]).trim()
          if (stringOption.length > 0) {
            try {
              await tlService.addCompanyOption(
                token,
                id,
                tmpOption,
                stringOption
              )
            } catch (e) {
              console.log('error ' + convertFromErrorObject(t, e))
              this.setState({ error: convertFromErrorObject(t, e) })
              return false
            }
          }
        }
      }
    }

    // special options
    if (data?.company_options) {
      for (let tmpOption of Object.keys(data.company_options)) {
        if (data.company_options[tmpOption] !== undefined) {
          const stringOption = typeof data.company_options[tmpOption] === 'object' ? JSON.stringify(data.company_options[tmpOption]) : String(data.company_options[tmpOption]).trim()
          if (stringOption.length > 0) {
            try {
              await tlService.addCompanyOption(
                token,
                id,
                tmpOption,
                stringOption
              )
            } catch (e) {
              console.log('error ' + convertFromErrorObject(t, e))
              this.setState({ error: convertFromErrorObject(t, e) })
              return false
            }
          }
        }
      }
    }

    // default user_groups & user_group_options
    if (defaultCompanySettings?.user_groups) {
      for (let tmpGroup of defaultCompanySettings.user_groups) {
        try {
          const groupName = tmpGroup['name'].replaceAll(':COMPANY_NAME:', companyName)
          const new_group = await tlService.addGroup(token, id, groupName)

          // error in creation a new group
          const newGroupId = new_group.id
          if (newGroupId === undefined || newGroupId === 0) {
            console.log('error in creation a new group')
            continue
          }

          //  add group options
          if (newGroupId !== undefined && newGroupId > 0 && tmpGroup['options'] !== undefined) {
            for (let tmpGroupOption of Object.keys(tmpGroup['options'])) {
              let stringGroupOption = typeof tmpGroup['options'][tmpGroupOption] === 'object' ? JSON.stringify(tmpGroup['options'][tmpGroupOption]) : String(tmpGroup['options'][tmpGroupOption]).trim()
              stringGroupOption = stringGroupOption
                .replaceAll(':GROUP_ID:', newGroupId)
                .replaceAll(':COMPANY_ID:', id)
                .replaceAll(':COMPANY_NAME:', companyName)
                .replaceAll(':GROUP_NAME:', groupName)
              if (stringGroupOption.length > 0) {
                await tlService.addGroupOption(token, newGroupId, tmpGroupOption, stringGroupOption)
              }
            }

            // add users to group
            if (!tmpGroup['users'] || !isArray(tmpGroup['users'])) {
              continue
            }
            for (let tmpUser of tmpGroup['users']) {

              //  password, user_name, email are required
              if (!tmpUser['password'] || !tmpUser['user_name'] || !tmpUser['email']) {
                console.log('password, user_name, email are required')
                continue
              }

              // predefined values
              const country_id = tmpUser['country_id'] ? tmpUser['country_id'] : 'DE'
              const user_type_id = tmpUser['user_type_id'] ? tmpUser['user_type_id'] : 2
              const language_id = tmpUser['language_id'] ? tmpUser['language_id'] : 'de'
              const license_id = tmpUser['license_id'] ? tmpUser['license_id'] : 0
              const enabled = tmpUser['enabled'] ? tmpUser['enabled'] : 1

              // optional values
              const first_name = tmpUser['first_name'] ? tmpUser['first_name'] : undefined
              const last_name = tmpUser['last_name'] ? tmpUser['last_name'] : undefined
              const street = tmpUser['street'] ? tmpUser['street'] : undefined
              const house_number = tmpUser['house_number'] ? tmpUser['house_number'] : undefined
              const zip_code = tmpUser['zip_code'] ? tmpUser['zip_code'] : undefined
              const city = tmpUser['city'] ? tmpUser['city'] : undefined

              const userName = tmpUser['user_name']
                .replaceAll(':COMPANY_NAME:', companyName.replaceAll(' ', '-'))
                .replaceAll(':GROUP_NAME:', groupName.replaceAll(' ', '-'))
              const email = tmpUser['email']
                .replaceAll(':COMPANY_NAME:', companyName.replaceAll(' ', '-'))
                .replaceAll(':GROUP_NAME:', groupName.replaceAll(' ', '-'))

              const newUser = await tlService.addUser(token, newGroupId, country_id, user_type_id,
                language_id, userName, email, tmpUser['password'],
                first_name, last_name, street, house_number,
                zip_code, city, enabled, license_id)

              // error in creation a new user
              const newUserId = newUser.id
              if (newUserId === undefined || newUserId === 0) {
                console.log('error in creation a new group')
                continue
              }

              // set user rights
              if (typeof tmpUser['rights'] === 'object' && isArray(tmpUser['rights'])) {
                for (let tmpRight of tmpUser['rights']) {

                  // ignore wrong length rights
                  if (tmpRight.length !== 2) {
                    continue
                  }
                  if (tmpRight[1] === ':GROUP_ID:') {
                    tmpRight[1] = newGroupId
                  }
                  if (tmpRight[1] === ':COMPANY_ID:') {
                    tmpRight[1] = id
                  }
                  await tlService.addUserRight(token, newUserId, tmpRight[0], tmpRight[1])
                }
              }

              // set user options
              for (let tmpUserOption of Object.keys(tmpUser['options'])) {
                let stringUserOption = typeof tmpUser['options'][tmpUserOption] === 'object' ? JSON.stringify(tmpUser['options'][tmpUserOption]) : String(tmpUser['options'][tmpUserOption]).trim()
                stringUserOption = stringUserOption
                  .replaceAll(':GROUP_ID:', newGroupId)
                  .replaceAll(':COMPANY_ID:', id)
                  .replaceAll(':COMPANY_NAME:', companyName)
                  .replaceAll(':GROUP_NAME:', groupName)
                if (stringUserOption.length > 0) {
                  await tlService.addUserOption(token, newUserId, tmpUserOption, stringUserOption)
                }
              }
            }

          } else {
            console.log('error add group unsuccessful')
            this.setState({ error: 'error add group unsuccessful' })
            return false
          }
        } catch (e) {
          console.log('error add group' + convertFromErrorObject(t, e))
          this.setState({ error: convertFromErrorObject(t, e) })
          return false
        }
      }
    }

    // if no migration -> exit
    if (!data?.useMigration) return true

    // relation between old and new TC
    const oldToNew = {}

    const createdData = {
      companyId: id,
      categories: [],
      benchmarks: [],
    }

    //defaultCompanySettings.clone_tc
    const copyTC = async (tlService, token, fromValue, child = false) => {
      if (fromValue?.length) {
        for (let tc of fromValue) {
          let res
          try {
            res = await tlService.getFilteredTermCategories(
              token,
              {},
              { id: tc }
            )
          } catch (e) {
            console.log('ignore TC', tc)
            continue
          }

          // save
          const oldTC = res.data[0]

          const newName = {
            de: oldTC.name.de || '',
            en: (oldTC.name.en || '') + ' ' + data.name,
          }

          // add TC
          let newTermId = 0
          try {
            if (child) {
              const res = await tlService.addTermCategory(
                token,
                oldTC.locale_name,
                JSON.stringify(newName),
                JSON.stringify(oldTC.description),
                oldTC.category_type,
                oldTC.settings,
                oldTC.global_visible,
                oldTC.id
              )
              newTermId = res?.id
            } else {
              const res = await tlService.cloneTermCategory(
                token,
                oldTC.id,
                {
                  name: JSON.stringify(newName),
                }
              )
              newTermId = res?.id
            }
          } catch (e) {
            console.log('error add')
            if (child) {
              this.setState({
                error:
                  `error add Term Category ${JSON.stringify(
                    newName
                  )} ` + convertFromErrorObject(t, e),
              })
            } else {
              this.setState({
                error:
                  `error clone Term Category ${JSON.stringify(
                    newName
                  )} ` + convertFromErrorObject(t, e),
              })
            }
            await this.deleteOnError(createdData, token, tlService)
            return false
          }

          // add UGC
          if (newTermId) {
            createdData.categories.push(newTermId)
            oldToNew[oldTC.id] = newTermId
            try {
              await tlService.addTermCategoryUGC(
                token,
                newTermId,
                0,
                0,
                id
              )
            } catch (e) {
              console.log('error add UGC')
              this.setState({
                error:
                  `error add UGC to Term Category ${JSON.stringify(
                    newName
                  )} ` + convertFromErrorObject(t, e),
              })
              await this.deleteOnError(
                createdData,
                token,
                tlService
              )
            }
            try {
              await tlService.calculateCategory(token, newTermId)
            } catch (e) {
              console.log('error calculateCategory')
            }
          }
        }
      }
      return true
    }

    // clone TC loop
    if (!(await copyTC(tlService, token, defaultCompanySettings.clone_tc, true))) {
      return false
    }

    if (!(await copyTC(tlService, token, defaultCompanySettings.copy_tc, false))) {
      return false
    }

    let new_ai_settings

    if (data?.add_ai_settings) {
      if (data?.ai_settings && typeof data.ai_settings === 'object' && Object.keys(data.ai_settings).length > 0) {
        new_ai_settings = data.ai_settings
      }
    }

    // clone Benchmarks loop
    if (defaultCompanySettings?.clone_benchmarks?.length) {
      for (let bench of defaultCompanySettings.clone_benchmarks) {
        let res
        try {
          res = await tlService.getFilteredBenchmarks(
            token,
            {},
            { id: bench.id }
          )
        } catch (e) {
          continue
        }
        const oldBenchmark = res.data[0]

        if (!isObjectEmpty(bench['settings_tc_replacement'])) {
          for (let settingName of Object.keys(bench['settings_tc_replacement'])) {
            if (!isObjectEmpty(oldBenchmark.settings[settingName])) {
              for (let valueName of Object.keys(
                bench['settings_tc_replacement'][settingName]
              )) {
                if (
                  !isObjectEmpty(
                    oldBenchmark.settings[settingName][
                      'values'
                      ][valueName]
                  )
                ) {
                  if (
                    bench['settings_tc_replacement'][
                      settingName
                      ][valueName]['type'] === 'strArray'
                  ) {
                    oldBenchmark.settings[settingName][
                      'values'
                      ][valueName] = this.runForStrArray(
                      oldBenchmark.settings[settingName][
                        'values'
                        ][valueName],
                      oldToNew
                    )
                  } else if (
                    bench['settings_tc_replacement'][
                      settingName
                      ][valueName]['type'] === 'object'
                  ) {
                    oldBenchmark.settings[settingName][
                      'values'
                      ][valueName] = this.runForObject(
                      oldBenchmark.settings[settingName][
                        'values'
                        ][valueName],
                      bench['settings_tc_replacement'][
                        settingName
                        ][valueName],
                      oldToNew
                    )
                  }
                }
              }
            }
          }
        }

        // save a new benchmark
        const newBenchName = {
          de: oldBenchmark.name.de || '',
          en: (oldBenchmark.name.en || '') + ' ' + data.name,
        }

        let newBenchId = 0
        try {
          const res = await tlService.addBenchmark(
            token,
            JSON.stringify(newBenchName),
            JSON.stringify(oldBenchmark.description),
            oldBenchmark.benchmark_template_id,
            '{}',
            JSON.stringify(oldBenchmark.return_values),
            JSON.stringify(oldBenchmark.settings)
          )
          newBenchId = res?.id
        } catch (e) {
          console.log('error add benchmark')
          this.setState({
            error:
              `error add benchmark ${JSON.stringify(
                newBenchName
              )} ` + convertFromErrorObject(t, e),
          })
          await this.deleteOnError(createdData, token, tlService)
          return false
        }

        // add UGC
        if (newBenchId) {
          if (new_ai_settings?.text_generator?.length > 0) {
            for (let old_ai_id in new_ai_settings.text_generator) {
              if (new_ai_settings.text_generator[old_ai_id].id === bench?.text_generator_id) {
                new_ai_settings.text_generator[old_ai_id].benchmark = newBenchId
                break
              }
            }
          }

          createdData.benchmarks.push(newBenchId)
          try {
            await tlService.addBenchmarkUGC(
              token,
              newBenchId,
              0,
              0,
              id
            )
          } catch (e) {
            console.log('error add benchmark UGC')
            this.setState({
              error:
                `error add benchmark UGC ${JSON.stringify(
                  newBenchName
                )} ` + convertFromErrorObject(t, e),
            })
            await this.deleteOnError(createdData, token, tlService)
            return false
          }
        }
      }
    }

    if (new_ai_settings) {
      try {
        await tlService.addCompanyOption(
          token,
          id,
          'ai_settings',
          JSON.stringify(new_ai_settings)
        )

      } catch (e) {
        console.log('error ' + convertFromErrorObject(t, e))
        this.setState({ error: convertFromErrorObject(t, e) })
        return false
      }
    }
    return true
  }

  actionEdit = async (data) => {
    const { t } = this.props
    const {
      tlService,
      userData: { token },
    } = this.props

    try {
      await tlService.updateCompany(token, parseInt(data.id), data.name)
    } catch (e) {
      console.log('error ' + convertFromErrorObject(t, e))
      this.setState({ error: convertFromErrorObject(t, e) })
      return false
    }

    // update licenses

    const newLicenses = data.company_licenses.map((lic) => parseInt(lic))
    const company = await tlService.getCompanyById(
      token,
      parseInt(data.id)
    )
    if (company && company.company_licenses) {
      const toDelete = company.company_licenses.filter(
        (d) => !newLicenses.includes(d)
      )
      const toAdd = newLicenses.filter(
        (d) => !company.company_licenses.includes(d)
      )

      /*
      console.log('company.company_licenses');
      console.log(company.company_licenses);
      console.log('toAdd');
      console.log(toAdd);
      console.log('toDelete');
      console.log(toDelete);
      */

      for (let license of toAdd) {
        try {
          await tlService.addCompanyLicense(token, data.id, license)
        } catch (e) {
          console.log('error ' + convertFromErrorObject(t, e))
          this.setState({ error: convertFromErrorObject(t, e) })
          return false
        }
      }
      for (let license of toDelete) {
        try {
          await tlService.deleteCompanyLicense(
            token,
            data.id,
            license
          )
        } catch (e) {
          console.log('error ' + convertFromErrorObject(t, e))
          this.setState({ error: convertFromErrorObject(t, e) })
          return false
        }
      }
    }
    // options

    try {
      await tlService.deleteAllCompanyOption(token, parseInt(data.id))
    } catch (e) {
      console.log('error ' + convertFromErrorObject(t, e))
      this.setState({ error: convertFromErrorObject(t, e) })
      return false
    }

    for (let tmpOption of Object.keys(data.company_options)) {
      if (data.company_options[tmpOption] !== undefined) {
        const stringOption = typeof data.company_options[tmpOption] === 'object' ? JSON.stringify(data.company_options[tmpOption]) : String(data.company_options[tmpOption]).trim()
        if (stringOption.length > 0) {
          try {
            await tlService.addCompanyOption(
              token,
              parseInt(data.id),
              tmpOption,
              stringOption
            )
          } catch (e) {
            console.log('error ' + convertFromErrorObject(t, e))
            this.setState({ error: convertFromErrorObject(t, e) })
            return false
          }
        }
      }
    }
    return true
  }

  actionDelete = async (data) => {
    const { t } = this.props
    const {
      tlService,
      userData: { token },
    } = this.props

    // delete company
    try {
      if (parseInt(data.id) > 2)
        await tlService.deleteCompany(token, parseInt(data.id))
    } catch (e) {
      console.log('error ' + convertFromErrorObject(t, e))
      this.setState({ error: convertFromErrorObject(t, e) })
      return false
    }
    return true
  }

  actionMassMessagingCompany = async (data) => {
    const {
      tlService,
      userData: { token },
      t: translate
    } = this.props
    if (data.length > 0) {
      console.log('actionMassMessaging', data)
      const company_ids = []
      const group_ids = []
      const user_ids = []
      for (let id of data) {
        company_ids.push(id['id'])
      }

      try {
        const res = await tlService.getFilteredGroups(token, {},
          {
            return_values: '["id"]',
            extended_filter: `[["&company_id","i=[${company_ids.join(',')}]"]]`
          })
        if (res && res.data.length > 0) {
          for (let id of res.data) {
            group_ids.push(id['id'])
          }
        }
      } catch (e) {
        console.log('error ' + convertFromErrorObject(translate, e))
        this.setState({ error: convertFromErrorObject(translate, e) })
        return false
      }

      try {
        const res = await tlService.getFilteredUsers(token, {},
          {
            return_values: '["id", "user_name", "email"]',
            extended_filter: `[["&user_group_id","i=[${group_ids.join(',')}]"]]`
          })
        if (res && res.data.length > 0) {
          for (let id of res.data) {
            user_ids.push(id)
          }
        }
      } catch (e) {
        console.log('error ' + convertFromErrorObject(translate, e))
        this.setState({ error: convertFromErrorObject(translate, e) })
        return false
      }

      const rows = { user_ids }
      this.openModal(0, rows, 'modalSendMessage')
    }
  }

}

const mapStateToProps = ({ userData, modalData, openData, adminData }) => {
  return { userData, openData, modalData, adminData }
}

const mapDispatchToProps = (dispatch, { tlService }) => {
  return bindActionCreators(
    {
      ...getAllModalFunctions(),
      adminGetAllCompanies: adminGetAllCompanies(tlService),
      adminGetAllOptions: adminGetAllOptions(tlService),
      adminGetAllSettings: adminGetAllSettings(tlService),
    },
    dispatch
  )
}

export default compose(
  withTranslation(),
  withTLService(),
  connect(mapStateToProps, mapDispatchToProps)
)(Companies)
